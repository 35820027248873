<div class="header-wrapper">
  <div>
    <i *ngIf="!isMobileScreen" class="fas fa-bars fs-4 header-icon" (click)="onCloseClicked($event)"></i>
  </div>

  <div>
    <button
      #btn
      type="button"
      pButton
      icon="pi pi-angle-down"
      [label]="fullUserName"
      (click)="menu.toggle($event)"
      data-cy="coalition-header-dropdown"
    ></button>
    <p-tieredMenu #menu [model]="items" [popup]="true"></p-tieredMenu>
  </div>
</div>
